<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submitEmbalment" class="mt-10" v-if="!terms">
      <div class="mb-5">
        <label class="block mb-2">I (Please enter your name here)</label>
        <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
          <input
            type="text"
            placeholder="Name"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="embalment.name"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2"
          >Of Address (Please enter your address here)</label
        >
        <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
          <input
            type="text"
            placeholder="Address"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="embalment.address"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Name of Deceased</label>
        <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
          <input
            type="text"
            placeholder="Name of Deceased"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="name"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Relationship with the Deceased</label>
        <ValidationProvider
          name="Relationship"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            type="text"
            placeholder="Relationship with the Deceased"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="embalment.relationship_deceased"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Phone Number</label>
        <ValidationProvider
          name="Phone Number"
          rules="required|length:11"
          v-slot="{ errors }"
        >
          <input
            type="tel"
            placeholder="Phone Number"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="embalment.phone_number"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Email Address</label>
        <ValidationProvider
          name="Email Address"
          rules="required|email"
          v-slot="{ errors }"
        >
          <input
            type="email"
            placeholder="Email Address"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="embalment.email"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <ValidationProvider name="signature" rules="required">
          <input type="checkbox" id="signature" v-model="embalment.signature" />
          <label
            class="mx-5 text-red-500 cursor-pointer"
            for="signature"
            @click="terms = true"
          >
            I agree to the terms
          </label>
        </ValidationProvider>
      </div>
      <button
        class="w-full mt-24 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
        :class="invalid ? 'bg-gray-300' : 'bg-primary'"
        :disabled="invalid && submitting"
      >
        {{ submitting ? "Please wait..." : "Continue" }}
      </button>
    </form>
    <div class="my-10" v-if="terms">
      <h2 class="mb-2 font-bold text-sm">
        ALWAYS PRODUCE THE ORIGINAL BILLING SHEET ON ATTENDANCE AT THE MORTUARY.
        AS PHOTOCOPIES ARE NOT ACCEPTED.
      </h2>
      <p class="text-sm mb-3">
        Once a deposit is paid, we expect the remaining embalmment fees to be
        balanced in full within 24 hours. If these fees are not balanced within
        this time, we may not be able to proceed with embalmment of the corpse.
        You are required to pay storage fees not longer than one month in
        arrears, otherwise the corpse would be considered unclaimed. Viewing of
        the corpse will only be allowed if a member of the family is in
        possession of the original billing sheet & receiot or pavments. Viewing
        is not allowed on the day prior to (before) the collection date. Please
        call to book for viewing and note that viewing time is between 10.00am
        and 1.00pm daily. Casket and clothing (including underwear) should be
        deposited at the mortuary before <b>2.00pm</b> on the day preceding
        collection date of the corpse.
      </p>
      <p class="text-sm">
        For hygiene reasons we DO NOT ACCEPT TOILETRIES. Corpses can only be
        released between the hours of 8am to 6pm on any day of the week.
        <b
          >RELATIONS ARE NOT ALLOWED IN THE MORTUARY DURING BATHING AND
          DRESSING.</b
        >
        On the day of collection, family should be in possession of the original
        billing sheet, receipts and also a photocopy of the death certificate.
      </p>
      <button
        class="w-full mt-5 text-white bg-gray-800 py-3 rounded-md"
        @click="terms = false"
      >
        Okay, Noted
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import { createembalment } from "@/services/addmission";

export default {
  name: "EmbalmentModal",
  props: {
    corpse_id: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      embalment: {
        corpse_id: "",
        name: "",
        address: "",
        name_of_deceased: "",
        relationship_deceased: "",
        phone_number: "",
        email: "",
        signature: false,
      },
      submitting: false,
      terms: false,
    };
  },
  methods: {
    async submitEmbalment() {
      this.submitting = true;
      this.embalment.corpse_id = this.corpse_id;
      this.embalment.name_of_deceased = this.name;
      this.embalment.signature = this.embalment.signature.toString();
      try {
        const response = await createembalment(this.embalment);
        this.$emit("created", response.data.corpse);
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
      }

      this.submitting = false;
    },
  },
};
</script>
